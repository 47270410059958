.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.syncee-logo {
  width: 136px;
  height: 28px;
}

.search-header-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .search-header-wrapper-logo-selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .search-header-wrapper-selector {
    width: 100%;
  }

  form {
    width: 100%;
  }

 
}

@media screen and (max-width: 991px){
  .search-header-wrapper {
    flex-direction: column;
  }
  .search-header-wrapper-logo-selector {
    align-items: center;
    gap: 20px;
    justify-content:  space-between;
    width: 100%;
  }
 

}